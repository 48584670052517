import React, { createContext, useContext, useEffect } from "react";
import { AccountContext } from "../account/account";
import qs from "qs";
import axiosRetry from "axios-retry";
import axios from "axios";

axiosRetry(axios, {
  retryDelay: (retryCount) => {
    return retryCount * 1000; // retry after 1, 2, 3, ... seconds
  },
});

const APIProviderContext = createContext();

const APIProvider = (props) => {
  const BASE_API_URL = process.env.REACT_APP_GRIGORA_API_URL + "/";
  // const BASE_API_URL = "http://localhost:3607/";
  // const BASE_API_URL = "https://api-new-test.grigora.co/";

  const { userAttributes, currentUser, idToken, accessToken } =
    useContext(AccountContext) ?? {};

  async function downloadImage(link) {
    return await new Promise((resolve, reject) => {
      axios
        .get(link, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getPexelsImages(query, page = 1, per_page = 20) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/image/pexels/get";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            query: `${query}`,
            per_page: `${per_page}`,
            page: `${page}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getTweet(url) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/blocks/twitter/oembed";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            url: `${url}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function inviteCollaborator(project_id, collaborator_email, role) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/invite_member";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            member_email: collaborator_email,
            role: role,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function projectSlugExists(slug) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/slug_exists";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            slug: slug,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateCollaborator(
    project_id,
    member_id,
    name,
    slug,
    bio,
    role,
    phone,
    avatar,
  ) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/update_member";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            member_id: member_id,
            name: name,
            slug: slug,
            bio: bio,
            role: role,
            phone: phone,
            avatar: avatar,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function removeCollaborator(project_id, member_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/delete_member";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            member_id: member_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function resendCollaboratorInvite(project_id, collaborator_email) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/resend_member_invite_email";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: project_id,
            member_email: collaborator_email,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function cancelCollaboratorInvite(project_id, collaborator_email) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/cancel_member_invite";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: project_id,
            member_email: collaborator_email,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getCollaborators(project_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/members";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getTodos(project_id, year, month, date) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/todo/view";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            year,
            month,
            date,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function addTodo(project_id, year, month, date, title, status) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/todo/add";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            year,
            month,
            date,
            title,
            status,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateTodo(project_id, todoID, title, status) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/todo/update";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            todo_id: todoID,
            title,
            status,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateFormSettings(project_id, sitekey, secretkey) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/update_form_settings";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            sitekey: sitekey,
            secretkey: secretkey,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getSecrets(project_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/secrets";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function setSecrets(project_id, secrets) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/set_secrets";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            secrets: secrets,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getRemarks(project_id, post_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/remarks/get";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            cms_post_id: post_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function createRemark(project_id, post_id, action, thread_id, remark) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/remarks/create";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            cms_post_id: post_id,
            action: action,
            thread_id: thread_id,
            remark_object: remark,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateRemark(
    project_id,
    post_id,
    thread_id,
    remark_id,
    remark,
  ) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/remarks/update_remark";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            cms_post_id: post_id,
            thread_id: thread_id,
            remark_id: remark_id,
            remark_object: remark,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateRemarkThread(project_id, post_id, thread_id, status) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/remarks/update_thread";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            cms_post_id: post_id,
            thread_id: thread_id,
            status: status,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function deleteRemark(project_id, post_id, thread_id, remark_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/remarks/delete_remark";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            cms_post_id: post_id,
            thread_id: thread_id,
            remark_id: remark_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function deleteRemarkThread(project_id, post_id, thread_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/remarks/delete_thread";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            cms_post_id: post_id,
            thread_id: thread_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getAssignments(project_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/assignments/get";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function createAssignment(project_id, title, assignedTo) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/assignments/create";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            title: title,
            assignedTo: assignedTo,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function deleteAssignment(project_id, assignment_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/assignments/delete";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            assignment_id: assignment_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateAssignment(project_id, assignment_id, properties) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/assignments/update";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            assignment_id: assignment_id,
            ...properties,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function createAssignmentComment(project_id, assignment_id, comment) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/assignments/add_comment";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${project_id}`,
            assignment_id: assignment_id,
            comment_object: comment,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function listProjects() {
    return await new Promise((resolve, reject) => {
      const endpoint = "user/projects_detailed";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({ user_id: `${userAttributes?.sub}` }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function publishProject({ id }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/publish";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({ user_id: `${userAttributes?.sub}`, project_id: id }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getFreeAnalytics(projectID) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/get_analytics";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: projectID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getStorageInfo(projectID) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/get_storage";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: projectID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function uploadProjectImage(project_id, image) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/image/upload";
      const formData = new FormData();
      formData.append("project_id", project_id);
      formData.append("user_id", `${userAttributes?.sub}`);
      formData.append("image", image);

      axios
        .post(BASE_API_URL + endpoint, formData, {
          headers: {
            Authorization: idToken,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function deleteProjectImage(project_id, imageID) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/image/delete";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            image_name: imageID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function deleteProjectVideo(project_id, videoID) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/video/delete";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            video_name: videoID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateProjectFavicon(project_id, image) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/image/favicon_upload";
      const formData = new FormData();
      formData.append("project_id", project_id);
      formData.append("user_id", `${userAttributes?.sub}`);
      formData.append("image", image);

      axios
        .post(BASE_API_URL + endpoint, formData, {
          headers: {
            Authorization: idToken,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function updateProjectSitelogo(project_id, image) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/image/sitelogo_upload";
      const formData = new FormData();
      formData.append("project_id", project_id);
      formData.append("user_id", `${userAttributes?.sub}`);
      formData.append("image", image);

      axios
        .post(BASE_API_URL + endpoint, formData, {
          headers: {
            Authorization: idToken,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function updateProjectWebclip(project_id, image) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/image/webclip_upload";
      const formData = new FormData();
      formData.append("project_id", project_id);
      formData.append("user_id", `${userAttributes?.sub}`);
      formData.append("image", image);

      axios
        .post(BASE_API_URL + endpoint, formData, {
          headers: {
            Authorization: idToken,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function listProjectImages(project_id, limit, LastEvaluatedKey) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/image/list";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            limit: limit,
            LastEvaluatedKey: LastEvaluatedKey,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function listProjectVideos(project_id, limit, LastEvaluatedKey) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/video/list";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            limit: limit,
            LastEvaluatedKey: LastEvaluatedKey,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function createProjectVideoUploadSignedUrl(project_id, video_name) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/video/create_signed_upload_url";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            video_name: video_name,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function createProjectVideoConfirmSignedUpload(
    project_id,
    video_id,
    width,
    height,
  ) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/video/confirm_signed_upload";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            video_id: video_id,
            width: width,
            height: height,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getCMSPostData(project_id, post_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/cms/post_data";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            post_id: post_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function getCMSPostContent(project_id, post_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/cms/post_content";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            post_id: post_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function duplicatePage(project_id, page_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/duplicate_page";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            page_id: page_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function deletePage(project_id, page_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/delete_page";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            page_id: page_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function updatePageData(
    project_id,
    page_id,
    name = undefined,
    title = undefined,
    slug = undefined,
    seo_meta_desc = undefined,
    type = undefined,
    template = undefined,
    seo_meta_title = undefined,
  ) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/update_page_data";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            page_id: page_id,
            name: name,
            title: title,
            slug: slug,
            seo_meta_desc: seo_meta_desc,
            type: type,
            template: template,
            seo_meta_title: seo_meta_title,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function publishQueueCheck(projectID, queueID) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/queue_check";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: projectID,
            queue_id: queueID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function addNewProject({ name, slug }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/add";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            name: name,
            slug: slug,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  async function deleteProject({ project_id }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/delete";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateProjectData({
    project_id,
    name,
    slug,
    fonts,
    colors,
    colorVariants,
    customcode,
    typography,
    removeMadeWithGrigora,
  }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/update";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: project_id,
            name: name,
            slug: slug,
            fonts: fonts,
            colors: colors,
            colorVariants: colorVariants,
            customcode: customcode,
            typography: typography,
            removeMadeWithGrigora: removeMadeWithGrigora,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function changePassword({ previous_password, proposed_password }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "user/change_password";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            access_token: accessToken,
            previous_password,
            proposed_password,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function projectData({ id }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/data";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({ user_id: `${userAttributes?.sub}`, project_id: id }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getProjectForms({ projectID }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/form/names";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: projectID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getProjectFormResponses({ projectID, name }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/form/list";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: projectID,
            name: name,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function deleteFormResponse({ projectID, name, entryID }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/form/delete";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: projectID,
            name: name,
            entryID: entryID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function projectEditorLoad({ id }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/editor/load";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({ user_id: `${userAttributes?.sub}`, project_id: id }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function cmsEditorLoad(projectID, postID) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/cms/editor/load";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: projectID,
            post_id: postID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function projectDashboardLoad({ id }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/dashboard/load";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({ user_id: `${userAttributes?.sub}`, project_id: id }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function projectPagesData({ id }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/get_pages";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({ user_id: `${userAttributes?.sub}`, project_id: id }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function addNewPage({
    name,
    title,
    slug,
    seo_meta_desc,
    seo_meta_title,
    content,
    type,
    template,
    project_id,
  }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/add_page";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id,
            name: name,
            slug: slug,
            type,
            template,
            title,
            seo_meta_title,
            seo_meta_desc,
            content,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function updatePages({ project_id, pages_data, pages_contents }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/update_pages";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id,
            pages_data,
            pages_contents,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function getPageContent({ project_id, page_id }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/get_page_content";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id,
            page_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function sendVerificationEmail() {
    return await new Promise((resolve, reject) => {
      const endpoint = "user/send_verify_email";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            access_token: accessToken,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function userLastOnline(userID) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/user_online";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            self_user_id: `${userAttributes?.sub}`,
            user_id: userID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function userTrack(event, properties = {}) {
    return await new Promise((resolve, reject) => {
      const endpoint = "user/track";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            event_name: event,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function verifyEmail({ verification_code }) {
    return await new Promise((resolve, reject) => {
      const endpoint = "user/verify_email";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            access_token: accessToken,
            verification_code: verification_code,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function enableIntegration(projectID, integration) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/${integration}/enable`;
      console.log(endpoint);
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function disableIntegration(projectID, integration) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/${integration}/disable`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function projectComponents(projectID) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/get_components`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateComponent(projectID, componentID, title, content) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/update_component`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            component_id: `${componentID}`,
            title: `${title}`,
            content: `${content}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function deleteComponent(projectID, componentID) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/delete_component`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            component_id: `${componentID}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function addComponent(projectID, title, content, root_node) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/add_component`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            title: `${title}`,
            content: `${content}`,
            root_node: `${root_node}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateComponents(projectID, projectComponents) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/update_components`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            components: projectComponents,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getCMSPosts(projectID, filters = {}, sort, order) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/post_list`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            filters: filters,
            sort: sort,
            order: order,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getCMSCategories(projectID) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/category_list`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getCMSCategoryLinksMeta(projectID, categories) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/category_links_meta`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            categories: categories,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function addCMSCategory(
    projectID,
    name,
    slug,
    description,
    thumbnail,
    thumbnail_meta,
  ) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/add_category`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            name: name,
            slug: slug,
            description: description,
            thumbnail: thumbnail,
            thumbnail_meta: thumbnail_meta,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function exportCMSPosts(projectID, postIDs) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/export`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: projectID,
            cms_post_ids: postIDs,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function importCMSPosts(projectID, objectKey, replace) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/import`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: projectID,
            key: objectKey,
            replace: replace,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateCMSCategory(
    projectID,
    id,
    name,
    slug,
    description,
    thumbnail,
    thumbnail_meta,
  ) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/update_category`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            category_id: id,
            name: name,
            slug: slug,
            description: description,
            thumbnail: thumbnail,
            thumbnail_meta,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function deleteCMSCategory(projectID, id) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/delete_category`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            category_id: id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function addNewCMSPost(projectID, post_data, post_content) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/add_post`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            ...post_data,
            content: post_content,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function saveDraftCMSPost(projectID, postID, post_data, post_content) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/update_post`;

      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            post_id: `${postID}`,
            ...post_data,
            content: post_content,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function duplicateCMSPost(projectID, postID) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/duplicate_post`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: projectID,
            post_id: postID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function publishCMSPosts(projectID, postIDs) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/publish_post`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            post_id: postIDs,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function listScheduledCMSPosts(projectID) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/scheduled_posts`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function scheduleCMSPost(projectID, postID, scheduledTime) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/schedule_post`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            post_id: postID,
            scheduled_time: scheduledTime,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function unScheduleCMSPost(projectID, postID) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/unschedule_post`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            post_id: postID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function unpublishCMSPost(projectID, postID) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/unpublish_post`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            post_id: postID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function scheduleCMSPostCheck(projectID, postID) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/schedule_post_check`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: projectID,
            post_id: postID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function deleteCMSPosts(projectID, postIDs) {
    return await new Promise((resolve, reject) => {
      const endpoint = `project/cms/delete_post`;
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            project_id: `${projectID}`,
            post_id: postIDs,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getNotifications(action, projectID, notificationsLastKey) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/notifications/get";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            action: action,
            project_id: projectID,
            LastEvaluatedKey: notificationsLastKey,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function markNotificationRead(notifications) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/notifications/mark_read";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: `${userAttributes?.sub}`,
            notification_id: notifications,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res.data?.message);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getPendingDomainVerification(project_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/get_pending_domain_verification";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: project_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  async function checkPendingDomainSSL(project_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/check_pending_domain_cf_ssl";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: project_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function addProjectDomain(project_id, domain) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/add_domain";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: project_id,
            domain: domain,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function deleteProjectDomain(project_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/delete_domain";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: project_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function deleteProjectPendingDomainVerification(project_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/delete_pending_domain_verification";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: project_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function verifyProjectPendingDomain(project_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/verify_pending_domain";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: project_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function createTicket(
    name,
    email,
    subject,
    message,
    label,
    project_id,
  ) {
    return await new Promise((resolve, reject) => {
      const endpoint = "user/create_ticket";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: project_id,
            subject: subject,
            message: message,
            name: name,
            email: email,
            label: label,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getThemeData(theme_id) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/theme/get";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            theme_id: theme_id,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function installTheme(project_id, theme_id, colors, fonts) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/theme/install";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: project_id,
            theme_id: theme_id,
            colors: colors,
            fonts: fonts,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getThemeList() {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/theme/list";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getSectionList(category) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/section/list";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            category: category,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getSubscription(projectID) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/get_subscription";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getFiles(projectID, folder) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/files/get";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
            folder: folder,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function createFolder(projectID, directory, folder) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/files/create_folder";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
            directory: directory,
            folder: folder,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function deleteFiles(projectID, files) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/files/delete";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
            files: files,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getUploadSignedUrls(projectID, files, folder) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/files/upload_signed_urls";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
            files: files,
            folder: folder,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function confirmSignedUploads(projectID, files, folder) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/files/confirm_signed_uploads";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
            files: files,
            folder: folder,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function uploadFileS3(url, file, fileType) {
    return await new Promise((resolve, reject) => {
      axios
        .put(url, file, {
          headers: {
            "Content-Type": fileType,
          },
        })
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function migrationWordPressTest(projectID, websiteURL) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/migration/wordpress/test";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
            website_url: websiteURL,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function migrationWordPressAdd(projectID, websiteURL) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/migration/wordpress/add";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
            website_url: websiteURL,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function migrationList(projectID, mode) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/migration/list";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
            mode: mode,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function createStripePaymentLink(projectID, plan, promo) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/stripe/create_checkout_link";

      var currentURL =
        process.env.REACT_APP_NODE_ENV === "development"
          ? window.location.protocol + "//" + window.location.host + "/"
          : undefined;

      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
            plan: plan,
            redirectTo: currentURL,
            promo: promo,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function createLSPaymentLink(projectID, plan, promo) {
    return await new Promise((resolve, reject) => {
      const endpoint = "user/lemonsqueezy/create_checkout_link";

      var currentURL =
        process.env.REACT_APP_NODE_ENV === "development"
          ? window.location.protocol + "//" + window.location.host + "/"
          : undefined;

      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            email: userAttributes?.email,
            name: `${userAttributes?.["custom:given_name"] ?? ""} ${
              userAttributes?.["custom:family_name"] ?? ""
            }`,
            plan: plan,
            redirectTo: currentURL,
            promo: promo,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function getUserData() {
    return await new Promise((resolve, reject) => {
      const endpoint = "user/data";

      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function cancelProjectSubscription(projectID, subscriptionID) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/stripe/cancel_subscription";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
            subscription_id: subscriptionID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function listInvoicesSubscription(projectID) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/stripe/list_invoices";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function redeemSZLicense(licenseCode) {
    return await new Promise((resolve, reject) => {
      const endpoint = "user/saaszilla/redeem";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            license_key: licenseCode,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function manageSubscription(projectID) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/stripe/manage_subscription";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function manageLSSubscription() {
    return await new Promise((resolve, reject) => {
      const endpoint = "user/lemonsqueezy/customer_portal";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getSEOSettings(projectID) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/seo";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function updateSEOSettings(projectID, seo) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/seo/update";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            project_id: projectID,
            seo: seo,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function getProductHuntPost(url) {
    return await new Promise((resolve, reject) => {
      const endpoint = "project/product_hunt_post";
      axios
        .post(
          BASE_API_URL + endpoint,
          qs.stringify({
            user_id: userAttributes?.sub,
            url: url,
          }),
          {
            headers: {
              Authorization: idToken,
            },
          },
        )
        .then((res) => {
          resolve(res?.data?.Output?.post);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  return (
    <APIProviderContext.Provider
      value={{
        BASE_API_URL,
        projectEditorLoad,
        projectDashboardLoad,
        inviteCollaborator,
        getCollaborators,
        addNewProject,
        listProjects,
        publishProject,
        sendVerificationEmail,
        verifyEmail,
        projectData,
        projectPagesData,
        getPageContent,
        changePassword,
        publishQueueCheck,
        addNewPage,
        updatePageData,
        updatePages,
        uploadProjectImage,
        listProjectImages,
        listProjectVideos,
        deleteProject,
        updateProjectData,
        updateProjectFavicon,
        updateProjectSitelogo,
        updateProjectWebclip,
        deletePage,
        duplicatePage,
        getSecrets,
        setSecrets,
        updateFormSettings,
        getCMSPostData,
        getCMSPostContent,
        enableIntegration,
        disableIntegration,
        addComponent,
        updateComponent,
        deleteComponent,
        projectComponents,
        updateComponents,
        getFreeAnalytics,
        getStorageInfo,
        getCMSPosts,
        addNewCMSPost,
        saveDraftCMSPost,
        duplicateCMSPost,
        deleteCMSPosts,
        publishCMSPosts,
        getCMSCategories,
        addCMSCategory,
        getCMSCategoryLinksMeta,
        cancelCollaboratorInvite,
        resendCollaboratorInvite,
        userLastOnline,
        userTrack,
        getRemarks,
        createRemark,
        updateRemark,
        updateRemarkThread,
        deleteRemark,
        deleteRemarkThread,
        getAssignments,
        createAssignment,
        createAssignmentComment,
        updateAssignment,
        getNotifications,
        getPendingDomainVerification,
        addProjectDomain,
        deleteProjectDomain,
        deleteProjectPendingDomainVerification,
        verifyProjectPendingDomain,
        createTicket,
        getTweet,
        getThemeData,
        getThemeList,
        installTheme,
        getFiles,
        deleteFiles,
        createFolder,
        getUploadSignedUrls,
        confirmSignedUploads,
        uploadFileS3,
        updateCollaborator,
        removeCollaborator,
        updateCMSCategory,
        scheduleCMSPost,
        scheduleCMSPostCheck,
        markNotificationRead,
        projectSlugExists,
        deleteCMSCategory,
        exportCMSPosts,
        importCMSPosts,
        importCMSPosts,
        deleteProjectImage,
        deleteProjectVideo,
        getSubscription,
        createProjectVideoConfirmSignedUpload,
        createProjectVideoUploadSignedUrl,
        getTodos,
        addTodo,
        updateTodo,
        unpublishCMSPost,
        migrationWordPressTest,
        migrationWordPressAdd,
        migrationList,
        deleteAssignment,
        getPexelsImages,
        downloadImage,
        createStripePaymentLink,
        cancelProjectSubscription,
        cmsEditorLoad,
        listInvoicesSubscription,
        manageSubscription,
        getProductHuntPost,
        unScheduleCMSPost,
        listScheduledCMSPosts,
        getSEOSettings,
        updateSEOSettings,
        getSectionList,
        getProjectFormResponses,
        getProjectForms,
        deleteFormResponse,
        createLSPaymentLink,
        manageLSSubscription,
        getUserData,
        redeemSZLicense,
        checkPendingDomainSSL,
      }}
    >
      {props.children}
    </APIProviderContext.Provider>
  );
};
export { APIProvider, APIProviderContext };
